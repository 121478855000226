//generate clases using the scss vars on this file, for background and colors for example: .yellow-color: { var(--yellow) } .ye 
:root {
  --yellow: #F8E20D;
  --yellow-border-darken: linear-gradiente(180deg, #E8710B 0%, #FCC92F 100%)
  --yellow-gradient: linear-gradient(180deg, var(--yellow) 0%, #FEBF00 100%);
  --golden: rgba(193, 152, 6, 1);
  --yellow-darken: rgba(255, 198, 8, 1);
  --violet: rgba(132, 34, 91, 1);
  --black: rgba(49, 49, 49, 1);
  --blue: linear-gradient(180deg, #1B4E7C, #2A6194, #3172AC, #1672C6);
  --blue-darken: rgba(8, 36, 53, 1);
  --red: rgb(219, 14, 14);
  --purple: rgba(97, 39, 120, 1);
  --purple-soft: rgba(165, 129, 163, 1);
  --white: rgba(255, 255, 255, 1);
  --main-purple:linear-gradient(180deg, #6a0b42 0%, #0d070a 100%);
}

.yellow-darken-color {
  color: var(--yellow-darken);
}
.black-color {
  color: var(--black);
}

.white-color {
  color: var(--white);
}
