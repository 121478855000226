/* Fuente Poppins Regular */
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Fuente Poppins Light */
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Fuente Poppins Bold */
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Carton six';
  src: url('/assets/fonts/Carton-six/Carton_Six.ttf') format('truetype');
}

