* {
  font-family: 'Poppins', sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import 'vars.scss';
@import 'fonts.scss';

.img-store{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 60px;
  height: 60px;
  margin-left: -10px;
  margin-top: -15px;
  @media(min-width:700px){
    width: 80px;
    height: 80px;
  }
  @media(max-width:400px){
    width: 40px;
    height: 40px;
  }
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
